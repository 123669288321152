<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      height="53"
      elevation="0"
    >
      <v-container>
        <v-row align="center">
          <v-col cols="6" sm="auto">
            <router-link to="/" @click.native="$vuetify.goTo(0); showMenu = false;">
              <v-img src="@/assets/img/CACAO-LOGO-color-negro.svg" height="30px" contain position="left"></v-img>
            </router-link>
          </v-col>
          <v-col class="d-none d-sm-block text-right">
            <router-link to="/sobre" class="header-link mx-4 circular-bold" @click.native="$vuetify.goTo('#about-block')">{{$t('sobre')}}</router-link>
            <router-link to="/proyectos" class="header-link mx-4 circular-bold" @click.native="$vuetify.goTo('#proyectos')">{{$t('proyectos')}}</router-link>
            <router-link to="/contacto" class="header-link mx-4 circular-bold" @click.native="$vuetify.goTo('#footer')">{{$t('contacto')}}</router-link>
            <span class="d-inline-block mx-4 pointer lang-selector" @click="$i18n.locale = 'en'" v-bind:class="{'active':$i18n.locale == 'en'}">ENG</span>
            <span class="d-inline-block mx-4 pointer lang-selector" @click="$i18n.locale = 'es'"  v-bind:class="{'active':$i18n.locale == 'es'}">ESP</span>
          </v-col>
          <v-col class="d-sm-none text-right px-0">
            <v-btn icon @click="showMenu = !showMenu">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <div class="menu-movil d-sm-none" v-bind:class="{'cerrado':!showMenu}">
      <v-container fluid>
        <v-row>
          <v-col class="text-center">
            <span class="d-inline-block mx-4 pointer lang-selector" @click="$i18n.locale = 'en'" v-bind:class="{'active':$i18n.locale == 'en'}">ENG</span>
            <span class="d-inline-block mx-4 pointer lang-selector" @click="$i18n.locale = 'es'"  v-bind:class="{'active':$i18n.locale == 'es'}">ESP</span>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="4" class="text-center">
            <v-btn text @click="$vuetify.goTo('#about-block'); showMenu = false"><span style="text-transform: none">{{$t('sobre')}}</span></v-btn>
          </v-col>
          <v-col cols="4" class="text-center">
            <v-btn text @click="$vuetify.goTo('#proyectos'); showMenu = false"><span style="text-transform: none">{{$t('proyectos')}}</span></v-btn>
          </v-col>
          <v-col cols="4" class="text-center">
            <v-btn text @click="$vuetify.goTo('#footer'); showMenu = false;"><span style="text-transform: none">{{$t('contacto')}}</span></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    showMenu: false
  }),
  watch: {
  },
  mounted: function () {
    window.addEventListener('scroll', this.onScrollCount)
  },
  methods: {
    onScrollCount: function () {
      // this.$router.push(`/${newFoo}/${this.bar}/${this.oof}/${this.rab}`)

      var scrollTop = document.documentElement.scrollTop
      var aboutPosition = document.querySelector('#about-block').offsetTop
      var proyectoPosition = document.querySelector('#proyectos').offsetTop
      var footerPosition = document.querySelector('#footer').offsetTop

      if (scrollTop < aboutPosition) {
        this.$router.push('/')
      } else {
        if (scrollTop < proyectoPosition) {
          this.$router.push('/sobre')
        } else {
          if (scrollTop < footerPosition) {
            this.$router.push('/proyectos')
          } else {
            this.$router.push('/contacto')
          }
        }
      }

      console.log(aboutPosition + ' ' + scrollTop)
    }
  }

}
</script>

<i18n>
  {
    "en": {
      "sobre": "About us"
    }
  }
</i18n>
