<template>
  <div class="home">
    <template v-if="loadedHome">
      <div class="d-flex d-md-none">
        <v-container>
          <v-row>
            <v-col class="pa-0">
              <v-carousel height="100vw" hide-delimiters :show-arrows="false">
                <v-carousel-item transition="none" v-for="(imagen, img) in home.imagenes" :key="'imagen-'+img" :src="'https://cacaocms.actstudio.xyz/'+imagen.path"></v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div
        class="d-none d-md-flex"
        style="
          height: calc(100vh - 53px);
          background-color: #4f4cf3;
        "
      >
        <v-container fluid class="py-0">
          <v-row class="my-0" align-md="center" style="height: calc(100vh - 53px)">
            <v-col cols="auto" class="px-0 py-0">
              <v-img
                :src="'https://cacaocms.actstudio.xyz/'+imagenHome"
                width="calc(100vh - 53px)"
                :contain="true"
                aspect-ratio="1"
                @click="siguienteImagen"
                position="left"
                class="cacao-pointer"
              ></v-img>
            </v-col>
            <v-col class="d-none d-md-block px-6 py-0">
              <div class="d-block mx-auto bloque-logo-cover">
                <v-img src="../assets/img/CACAO-LOGOTYPE-blanco.svg" contain></v-img>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
    <div id="about-block">
      <v-container class="py-0">
        <v-row class="my-0" align="center" justify="center" style="height: 400px">
          <v-col cols="10" sm="6" lg="4" class="px-0">
            <v-img src="../assets/img/CACAO-LOGO-blanco-blanco.svg" contain></v-img>

            <p class="white--text mt-12 texto-corrido">
              {{$t('descripcionCacao')}}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div id="proyectos" class="proyectos">
      <v-container fluid>
        <v-row>
          <template v-for="(proyecto, p) in proyectos">
            <v-col cols="6" sm="3" class="pa-0" :key="'proyecto-' + p">
              <v-img
                :src="'https://cacaocms.actstudio.xyz/' + proyecto.cover.path"
                aspect-ratio="1"
                @click="
                  dialog = true;
                  carouselProyectos = p;
                "
                class="cacao-pointer"
              ></v-img>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </div>

    <div id="footer">
      <v-container class="pb-0 pb-md-12">
        <v-row class="my-12 mb-0 mb-md-12">
          <v-col cols="12" sm="6" md="12" class="pb-12" style="line-height: 1.2;">
            <span class="white--text d-block mb-4"><span class="circular-bold">CACAO</span> PRODUCCIONES</span>
            <a href="mailto:hola@cacao.mx" class="white--text d-inline-block circular-bold mb-4"
              >hola@cacao.mx</a
            >
            <br>
            <a href="tel:5218112537466" class="white--text d-inline-block circular-bold mb-4"
              >+ 52 (81) 1253 7466</a
            >
            <br>

            <v-btn icon href="https://www.facebook.com/cacaoproduccionesmx" target="_blank" color="white">
              <v-icon>mdi-facebook</v-icon>
            </v-btn>

            <v-btn icon href="https://www.instagram.com/cacaoproduccionesmx" target="_blank" color="white">
              <v-icon>mdi-instagram</v-icon>
            </v-btn>

            <span class="white--text d-block mt-6 circular-bold"> Torre IOS Campestre </span>

            <span class="white--text d-block circular-bold"> Av. Ricardo Margain 575 </span>

            <span class="white--text d-block circular-bold">
              Parque Corporativo Santa Engracia
            </span>

            <span class="white--text d-block circular-bold">C.P. 66267- México</span>
          </v-col>
          <v-col cols="12" md="0" class="px-0 pb-0 pt-12 d-md-none">
            <v-img src="../assets/cacao-footer-OPCION-1.png"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-model="dialog" fullscreen hide-overlay>
      <v-card color="#4f4cf3">
        <div style="position: relative;">
          <div style="position: absolute; top: 20px; right: 20px;">
            <v-img class="pointer" src="../assets/img/x.svg" width="30px" @click="dialog = false;"></v-img>
          </div>
          <v-container fluid>
            <v-row style="height: 100vh" align="center">
              <v-col class="px-0 py-12">
                <v-carousel
                  hide-delimiters
                  height="calc(100vh - 96px)"
                  v-model="carouselProyectos"
                  :show-arrows="!$vuetify.breakpoint.xs"
                >
                  <v-carousel-item
                    v-for="(proyecto, p) in proyectos"
                    :key="'naskdjnaskj-' + p"
                  >
                    <v-img
                      :src="
                        'https://cacaocms.actstudio.xyz/' + proyecto.cover.path
                      "
                      :contain="true"
                      height="100%"
                      eager
                    ></v-img>
                  </v-carousel-item>
                  <template v-slot:prev="{ on, attrs }">
                    <v-img src="../assets/img/flecha_izquierda.svg" class="pointer ml-4" v-bind="attrs" v-on="on" width="40px" :contain="true">
                    </v-img>
                  </template>
                  <template v-slot:next="{ on, attrs }">
                    <v-img src="../assets/img/flecha_derecha.svg" class="pointer mr-4" v-bind="attrs" v-on="on" width="40px" :contain="true">
                    </v-img>
                  </template>
                </v-carousel>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      proyectos: [],
      dialog: false,
      carouselProyectos: 0,
      home: {},
      loadedHome: false,
      imagenInicial: 0
    }
  },
  computed: {
    imagenHome: function () {
      if (this.loadedHome) {
        return this.home.imagenes[this.imagenInicial].path
      }
      return false
    }
  },
  components: {},
  beforeMount: function () {
    const aux = this
    fetch('https://www.cacaocms.actstudio.xyz/api/collections/get/Proyectos')
      .then((res) => res.json())
      .then(function (res) {
        aux.proyectos = res.entries
      })
    fetch('https://www.cacaocms.actstudio.xyz/api/singletons/get/Home')
      .then((data) => data.json())
      .then(function (res) {
        aux.home = res
        aux.loadedHome = true
      })
  },
  methods: {
    siguienteImagen: function () {
      if (this.loadedHome) {
        if (this.home.imagenes.length - 1 > this.imagenInicial) {
          this.imagenInicial++
        } else {
          this.imagenInicial = 0
        }
      }
    }
  }
}
</script>
